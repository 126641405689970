import { Modal, ModalBody, Text, Button, Flex, InjectedModalProps, useMatchBreakpoints, Box } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'


const Chapter2Modal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss }) => {
  const { isMobile } = useMatchBreakpoints()

  const { t } = useTranslation()

  return (
    <Modal title={t('Welcome to Chapter 2')} pb="32px" onDismiss={onDismiss} style={{ maxWidth: '90vw', width: '1000px' }}>
      <ModalBody style={{ width: '100%', padding: '24px' }}>
        <div style={{ position: 'relative', paddingTop: '56.25%', width: '100%' }}>
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '12px',
            }}
            src={`https://www.youtube.com/embed/bSD4z-s5lso`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        {isMobile ? (
          <Box mt="32px" mb="8px">
            <Text fontSize="18px" fontWeight="700" mb="32px">Reach out now:</Text>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Button
                as="a"
                href="https://mail.google.com/mail/?view=cm&fs=1&to=investor@xoxlabs.io"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  margin: "2px",
                  fontSize: "18px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "12px",
                  background: "linear-gradient(90deg, #7645D9 0%, #452A7A 100%)",
                  boxShadow: "0px 4px 12px rgba(118, 69, 217, 0.4)",
                  transition: "all 0.2s ease-in-out",
                  width: isMobile ? "100%" : "auto"
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-2px)"
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)"
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22 6l-10 7L2 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                investor@xoxlabs.io
              </Button>
            </Flex>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Button
                as="a"
                href="https://t.me/NinjaXo1"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  margin: "2px",
                  fontSize: "18px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "12px",
                  background: "linear-gradient(90deg, #1FC7D4 0%, #0098A1 100%)",
                  boxShadow: "0px 4px 12px rgba(31, 199, 212, 0.4)",
                  transition: "all 0.2s ease-in-out",
                  width: isMobile ? "100%" : "auto"
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-2px)"
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)"
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M22 2L11 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22 2L15 22l-4-9l-9-4l20-7z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                @NinjaXo1
              </Button>
            </Flex>
          </Box>)
          :
          (<Flex mt="32px" mb="8px">
            <Text fontSize="24px" fontWeight="700" mb="8px" mr="32px" paddingTop="8px">Reach out now:</Text>
            <Flex flexDirection="row" alignItems="center">
              <Button
                as="a"
               href="https://mail.google.com/mail/?view=cm&fs=1&to=investor@xoxlabs.io"
                target="_blank"
                style={{
                  margin: "4px",
                  marginRight: "8px",
                  fontSize: "18px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "12px",
                  background: "linear-gradient(90deg, #7645D9 0%, #452A7A 100%)",
                  boxShadow: "0px 4px 12px rgba(118, 69, 217, 0.4)",
                  transition: "all 0.2s ease-in-out",
                  width: isMobile ? "100%" : "auto"
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-2px)"
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)"
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22 6l-10 7L2 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                investor@xoxlabs.io
              </Button>
              <Button
                as="a"
                href="https://t.me/NinjaXo1"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  margin: "4px",
                  fontSize: "18px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "12px",
                  background: "linear-gradient(90deg, #1FC7D4 0%, #0098A1 100%)",
                  boxShadow: "0px 4px 12px rgba(31, 199, 212, 0.4)",
                  transition: "all 0.2s ease-in-out",
                  width: isMobile ? "100%" : "auto"
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-2px)"
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)"
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M22 2L11 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22 2L15 22l-4-9l-9-4l20-7z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                @NinjaXo1
              </Button>
            </Flex>
          </Flex>)}
      </ModalBody>
    </Modal>
  )
}

export default Chapter2Modal
